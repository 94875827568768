// Example preview.js file

import React from 'react';
import tw from 'twin.macro';
import { Text, TextType } from '../core-ui/Text';
import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews';
import { Layout } from '../components/Layout';
import SEO from '../components/SEO';
import { SliceWrapper } from '../core-ui/SliceWrapper';
import { Wrapper } from '../core-ui/Wrapper';

const PreviewPage = () => {
  return (
    <Layout title="Loading prismic preview....">
      <SEO title="Loading...." />
      <SliceWrapper last>
        <Wrapper>
          <div css={tw`py-130`}>
            <Text type={TextType.h1}>Loading preview...</Text>
          </div>
        </Wrapper>
      </SliceWrapper>
    </Layout>
  );
};

export default withPrismicPreviewResolver(PreviewPage);
